<template> 
<div class="left_banner"> 
    <!-- <div class="logo_img"> <img src="../../public/static/images/logo_top1.png" width="75" height="20" ></div> -->
    <div class="left_title">   “奥普特”杯机械工程<br>分论坛  </div>
    <div class="left_time">  {{row.m_address}}{{row.m_time}}   </div>
    <div class="qrcode"> <img src="../../public/static/images/sing.png" width="75" height="75" >   码上报名 </div> 
</div>
<div class="right_banner">
    <!-- <div class="logo_img"> <img src="../../public/static/images/logo_top1.png" width="75" height="20" ></div> -->
    <div class="left_title">  “奥普特”杯机械工程<br>分论坛 </div>
    <div class="left_time">  {{row.m_address}}{{row.m_time}}   </div>
    <div class="qrcode"> <img src="../../public/static/images/sing.png" width="75" height="75" >   码上报名 </div> 
</div>
<el-row>
  <el-col :span="24" class="banner">
    <div class="banner_title">   2024年广东省<br> <strong> 研究生学术论坛</strong> </div> 
    <div class="banner_t">  “奥普特”杯机械工程分论坛 </div>
    <div class="banner_addres">    {{row.m_address}} <span class="banner_addres_p"> |</span>  
        2024年6月
        <!-- {{row.m_time}}  -->
    </div>
    <ul>
        <li>主办方：{{row.m_sponsor}}</li>
        <li>承办方：{{row.m_cosponsor}}</li>
        <li>协办方：{{row.m_undertaker}}</li> 
    </ul>
  </el-col>
  <el-col :span="24" class="main"> 
        <div class="meeting_time">
           <b>  距离会议开始还有：</b><countDown></countDown>
           <span class="bt_img">
                <img src="../../public/static/images/button_sin.png" @click="go('/applicants')"> 
                <img src="../../public/static/images/button_t.png" style="left:108px" @click="go('/contribution')"> 
           </span>
        </div>
        <div class="content">
            <div class="text">  
                <h1>{{row.m_title}}</h1>
                <span v-html="row.m_content"></span> 
            </div>
        </div>
  </el-col>
</el-row>

</template> 
<script>

import countDown from '../components/CountDown';
import {getMeetingRow} from '../api/index'; 

 export default {
    components: {
        countDown         
    },
  name: 'IndexVue',
  data() {
        return { 
            row:{
                m_address:'',
                m_time:'',
                m_title:'',
            }, 
        }
    },  
    methods: {
       go(path){  
            this.$router.push({
            path:path,
            query:{
                   
                },
            });
       }
         
    },
   async created(){
    
        let m_id = this.$route.query.m_id; 
        if(m_id==undefined) m_id = this.$store.getters.getMid //空m_id
       
        const res =  await getMeetingRow({'m_id':m_id});
            this.row = res.data.result;  
            this.$store.commit("setMid",this.row.m_id) //m_id 存起来   
 
        // console.log(this.$store.getters.getMid) 
        document.title = this.row.m_title
    }
  
}
</script>

<style scoped>
.left_banner{
    position: fixed;
    padding: 5px;
    width: 80px;
    min-height: 160px;
    top: 230px;
    left: 30px;
    /* border: solid 1px red; */
    z-index: 100000;
    /* background: url('../../public/static/images/left_bg.png') no-repeat; */
    background: #21a346;
    background-size: 100% 100%;
    padding-top: 20px;
}
.right_banner{
    position: fixed;
    padding: 5px;
    width: 80px;
    min-height: 160px;
    top: 230px;
    right: 30px; 
    z-index: 100000;
    /* background: url('../../public/static/images/left_bg.png') no-repeat; */
    background: #21a346;
    background-size: 100% 100%;
    padding-top: 20px;
}
.logo_img{
    width:80px;
    height: 40px;
    margin-top: 10px;
}
.left_title{
    font-size: 14px;
    line-height: 26px;
    color: #FFF;
}
.left_time{
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #FFE0CF;
    text-align: center;
}
.qrcode{
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #FFF59B;
}
.banner{
    background-image: url('../../public/static/images/banner.png');
    height: 600px;
    width: 100%;
    background-size: 100% 600px;
    background-repeat: no-repeat;
}
.banner_title{
    background-image: url('../../public/static/images/banner_title.png');
    width: 328px;
    height: 125px; 
    padding-top: 25px;
    background-size: auto;
    margin: 30px auto;
    font-size: 38px;
    line-height: 50px;
}
.banner_t{
    width: 1100px;
    height: 55px; 
    margin: 0 auto;
    color: #225AC9;
    font-size: 36px;
    font-weight: bolder;
}
.banner_addres{
    background-image: url('../../public/static/images/banner_bg.png');
    width: 250px;
    height: 36px;
    margin: 20px auto; 
    font-size: 16px;
    color: #ffffff;
    background-repeat: no-repeat;
    line-height: 36px;
    background-size: 100% 100%;
    /* border:solid 1px #225AC9; */
}
.banner_addres_p{
    background-image: url('../../public/static/images/b_bg_p.png');
    width: 1px;
    height: 30px;
    background-size: 100% 100%;
}
.banner ul li{
    width: 1100px;
    margin: 0 auto;
    line-height: 30px;
    font-size: 16px;
    font-weight: bolder;
}
.main{
    background: #F8F8F8;
    min-height: 800px;
}
.main .meeting_time{
    width: 555px;
    height: 50px;
    border: solid 1px #FF6600;
    margin: 30px auto;
    line-height: 50px;
    border-radius: 5px;
    text-align: left;
    font-weight: bolder;
    padding-left: 30px;
    background: #ffffff;
}
.meeting_time .title{
    color: #FF6600;
}
.meeting_time .bt_img{ 
    display: inline-block; 
    line-height: 50px;
    margin-left: 30px; 
    position: relative;
}
.meeting_time .bt_img img{  
    position: absolute;
    top: -23px;  
    left: 0;
}
.content{
    width: 1100px;
    min-height: 600px;
    background: #ffffff;
    margin: 0 auto;
    font-size: 14px;
    text-align: left;
    padding: 0 50px;
    margin-bottom: 30px;
}
.content h1{
    padding-top: 20px;
    line-height: 80px;
    color: #366CD7;
    text-align: center;
     
}
.content p{ 
    line-height: 28px; 
}
.content .text{
    width: 100%; 
    padding-bottom: 50px;
}
</style>
