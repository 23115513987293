<template>
<el-row>
  <el-col :span="24" style="background: #F7F6F6;">
    <div class="main">
        <div class="title">会议资料 </div>
            <div class="content"> 
                <el-table
                    :data="tableData"
                    class="table" 
                    >
                    <el-table-column
                        prop="file_name"
                        label="文件名称"
                        width="480"
                        style="height:80px;"
                        >
                        <template v-slot="scope">
                            <img src="../../public/static/images/tb1.png" width="16">
                            <span style="margin-left: 10px"> {{ scope.row.file_name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="upload_time"
                        label="更新时间"
                        width="200">
                        <template v-slot="scope"> 
                            <span style="margin-left: 10px"> {{ this.format(scope.row.upload_time) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="file_path"
                        label="下载"
                         width="200" >
                         <template v-slot="scope">
                            <img src="../../public/static/images/tb2.png">
                            <span style="margin-left: 10px"><a :href="scope.row.file_path">点击下载</a> </span>
                        </template>
                    </el-table-column>
                </el-table>
             

                <!-- <div class="page">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="100">
                    </el-pagination>
                </div> -->
               
            </div> 
       
    </div>
    </el-col>
</el-row>
</template> 

<script>
 
import {getMeetingData} from '../api/index'; 
export default {
  name: 'DataVue',
   data() {
        return {
          tableData: [{
            file_name: '“粤铭激光”杯机械工程报名表',
            upload_time: '2022-07-27',
            file_path: '点击下载'
          }, {
           file_name: '“粤铭激光”杯机械工程报名表',
            upload_time: '2022-07-27',
            file_path: '点击下载'
          }, {
           file_name: '“粤铭激光”杯机械工程报名表',
            upload_time: '2022-07-27',
            file_path: '点击下载'
          }, {
            file_name: '“粤铭激光”杯机械工程报名表',
            upload_time: '2022-07-27',
            file_path: '点击下载'
          }]
        }
    },
    methods:{
        format(times) 
        { 
            times = parseInt(times)*1000;
            var time = new Date(times); 
            var y = time.getFullYear(); 
            var m = time.getMonth()+1; 
                m = m < 10 ? "0" + m : m
            var d = time.getDate(); 
                d = d < 10 ? "0" + d : d
            var h = time.getHours(); 
                h = h < 10 ? "0" + h : h
            var mm = time.getMinutes(); 
                mm = mm < 10 ? "0" + mm : mm
            var s = time.getSeconds(); 
                s = s < 10 ? "0" + s : s
            return y+'-'+m+'-'+d+' '+h+':'+mm+':'+s; 
        }, 
    },
    created(){  
        // if(this.$store.state.u_id==''){ //没有报名参会
        //     this.$message.success({
        //             showClose: true,
        //             message: '请先报名参会!',
        //             type: 'warning'
        //         }) 
        //     this.$router.push({
        //         path: '/applicants?m_id='+this.$store.state.m_id,
        //     });
        // } 
        getMeetingData({ 
            'm_id':this.$store.state.m_id
            // 'm_id':this.$store.getters.getMid
        }).then(res=>{ 
            this.tableData = res.data.result  
            console.log(res)
        }) 
        
    }
}
</script>

<style scoped>
.table{
    width: 90%;
    margin: 0 auto;
}
.main{
    width: 1100px;
    min-height: 600px;
    background: #ffffff;
    margin: 40px auto; 
    padding-bottom: 5px;
}
.main .title{
    width: 1100px;
    line-height: 50px;
    background: #467DEB;
    text-align: center;
    color:#ffffff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: bolder;
    font-size: 16px;
    margin-bottom: 60px;
}
.main .content{
    padding: 0 30px;
    text-align: left;
    padding-bottom: 10px;
}
/* .main .content img{
    height: 150px;
} */
  
 
.page{
    width: 500px;
    margin: 50px auto;
}
</style>
