<template>
<el-row>
    <el-col :span="24">
        <div class="top_bg">
            <div class="top">
                <!-- <div class="top_title">
                    2023年广东省<br> 
                    研究生学术论坛 
                </div>
                <div class="top_title_name">
                    <h2> 前沿交叉学科研究生论坛</h2>
                    <p>地点: 东莞松山湖科技产业园区  &nbsp;&nbsp;&nbsp;&nbsp;时间: {{mstime}} </p>
                </div> -->
                <!-- <div class="logo_top">
                    <img src="../../public/static/images/logo_top.png" width="245" height="40">
                </div> -->
            </div> 
        </div>
    </el-col> 
</el-row>
<el-row>
  <el-col :span="24" style=" border-top: solid 2px #21a346;">
    <div class="header">
        <div class="logo">
            <!-- <img src="../../public/static/images/logo.png">
            <img src="../../public/static/images/logo_line.png"> -->
            <img src="../../public/static/images/logo_title.jpg"   height="55">
        </div>
        <ul> 
            <li v-for="val in headerData" :key="val.path"> 
                <router-link :to=val.path :class="column==val.path?'current':''" > {{val.name}}</router-link>
            </li> 
        </ul> 
            
            <el-link :underline="false"  @click="out" v-if="show" class="login_out">退出登录</el-link>  

    </div>  
  </el-col>
</el-row>

</template> 
<script> 
export default {
  name: 'HeaderVue',
   props:{
        headerData:Array,
        show:{
           type: Boolean,	
            default () {
                return false;
            },
        },
        column:{
            type: String,	
            default () {
                return '/';
            },
        },
        mstime:{
            type: String,	
            default () {
                return '';
            },
        }
        
   },
   data() {
        return {
            // show:false,
            // headerData: [
            //     {
            //         name: '参会主页', 
            //         path: '/'
            //     }, {
            //         name: '参会报名', 
            //         path: '/applicants'
            //     }, {
            //         name: '论文投稿', 
            //         path: '/contribution'
            //     }, {
            //         name: '会议动态', 
            //         path: '/news'
            //     }, 
            //     {
            //         name: '参会资料', 
            //         path: '/data'
            //     } 
            // ],
            // column:'/',
        }

    },
  methods:{ 
    out(){
        this.$store.commit('setPhone',''); 
        this.$store.commit('setUid',''); 
        this.$store.commit("setShow",false) 

        this.$message.success({
                            showClose: true,
                            message: '退出成功!', 
                        }) 
        this.$router.push({ path: '/?m_id='+this.$store.getters.getMid, }); 
        
    }

  },
  created(){  
     
  }
}
</script>

<style scoped> 

.top_bg{
    width: 100%;
    height: 150px;
    background: url('../../public/static/images/top_bg.jpg') no-repeat;
    background-size: 100% 100%;
}
.top{
    width: 1200px;
    margin: 0 auto;
}
.top_title{
    width: 300px;
    height: 108px;
    padding-top: 30px;
    line-height: 42px;
    color: #ffffff;
    font-size: 30px;
    /* border: #000000 solid; */
    font-weight: bold;
    text-align: left;
    float: left;
}
.top_title_name{
    width: 680px;
    height: 128px;
    padding-top: 10px;
    line-height: 30px;
    color: #ffffff;
    font-size: 26px;
    /* border: #000000 solid; */
    font-weight: bold;
    text-align: left;
    float: left;
}

.top_title_name p{
    color: #eeeeee;
    font-size: 16px;
    width: 458px;
    text-align: center;
    /* border: #000000 solid; */
    /* font-weight: normal; */
    margin-top: -15px;
}

.logo_top{
    width: 250px;
    height: 60px;
    float: right;
    margin-top: 60px;
    margin-right: 280px;
}

.login_out{
    line-height: 90px;
    font-size: 12px;
    color: red;
}
.out{
    font-size: 12px;
    color: red;
}
.header{
    width: 1200px;
    height: 80px; 
    margin: 0 auto; 
} 
.logo{
    width: 300px;
    float: left; 
    line-height:60px;
    text-align: left;
	margin-right: 60px;
}
.logo img{
    margin-top: 15px;
    padding: 0 5px;
}
.header ul li{
    list-style: none;
    float: left;
    width: 117px;
    line-height: 90px;
    font-size: 16px;
    font-weight: bolder;
}
a{
    color:#000000;
}
.current{
    color: #409EFF;
}
.header ul li a.hover{
    color: #409EFF;
}
 
</style>
